<template>

  <div class="home">

    <Header> </Header>

    <Navigation> </Navigation>

    <News> </News>

    <hr class="border-2 border-blue-800 w-11/12 mx-auto rounded">

    <div class="">

      <Products> </Products>

    </div>

    <hr class="border-2 border-blue-800 w-11/12 mx-auto rounded">

    <About> </About>

    <div class="bg-blue-800">

      <Kontakt> </Kontakt>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import News from '@/components/News.vue'
import Navigation from '@/components/Navigation.vue'
import Products from '@/components/Products.vue'
import About from '@/components/About.vue'
import Kontakt from '@/components/Kontakt.vue'

export default {
  name: 'Home',
  components: {
    Header,
    News,
    Navigation,
    Products,
    About,
    Kontakt
  },
  methods: {

    onload: function () {
      // console.log("hello");
      this.$store.dispatch('initialFetch');

    }

  },

  mounted: function(){

    this.onload();

  },
}
</script>

<style media="screen">

h1 {
  font-size: 7rem;
  font-weight: bold;
  font-family: 'Parisienne';
}

h2 {

  font-size: 3rem;
  font-family: 'Fjalla One';

}

li{
  font-size: 1.2rem;
  font-family: 'Arial';
  list-style-type: circle;
}

@media only screen and (max-width: 700px) {

  h1 {
    font-size: 4rem !important;

  }

  h2 {

    font-size: 2rem !important;

  }

  li{
    font-size: 1.2rem !important;

  }

}



</style>
