<template>
  <div v-if="this.$store.state.fetchedNews && this.$store.state.fetchedTitel" class="p-4 text-left mb-32 text-black lg:w-3/4 mx-auto m-8">

    <h1 class="text-blue-800 mb-16 text-center"> {{ title }}</h1>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">

      <div
      class=""
      :key="news.inhalt"
      v-for="news in allnews">

      <div class="h-full border-2 rounded-xl border-gray-300 p-4 transform hover:-translate-y-2 transition ease-in-out duration-500">

        <h3 class="text-blue-800 mb-2" v-html="news.titel"> </h3>

        <p v-html="news.inhalt">

        </p>

      </div>
    </div>

  </div>

</div>
</template>

<script>
export default {
  name: 'News',
  props: {

  },
  data() {
    return {

    }
  },
  methods: {

    compare(a, b) {
      if (a.datum > b.datum) {
        return -1;
      }
      if (a.datum < b.datum) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

  },

  computed: {

    allnews() {
      // console.log(this.$store.state.projekte[2].shorturl);
      return this.$store.state.news.slice().sort(this.compare)
    },

    title() {

      if(this.$store.state.fetchedTitel){

        let data = this.$store.state.titel;

        // var that = this;

        let filtered = data.filter(function (titel) {
          return titel.id == 1;
        });


        return filtered[0].titel;

      } else {

        return 'loading ...'

      }

    }

  },

}
</script>

<style media="screen">

p img {

  border-radius: 12px;
  width: 50%;
  margin-top: 20px;
}


</style>
