import { createStore } from 'vuex'

export default createStore({
  state: {

    news: [],
    gebaeck: [],
    welcome: [],
    titel: [],

    fetchedNews: false,
    fetchedGebaeck: false,
    fetchedWelcome: false,
    fetchedTitel: false,

  },
  mutations: {

    setFetchedNews(state, data){

      var i
      for (i = 0; i < data.length; i++){

        state.news[i] = data[i].acf;

        // console.log(state.projekte[i].titel);

      }

      state.fetchedNews = true;

    },

    setFetchedGebaeck(state, data){

      var i
      for (i = 0; i < data.length; i++){

        state.gebaeck[i] = data[i].acf;

        // console.log(state.projekte[i].titel);

      }

      state.fetchedGebaeck = true;

    },

    setFetchedWelcome(state, data){

      var i
      for (i = 0; i < data.length; i++){

        state.welcome[i] = data[i].acf;

        // console.log(state.projekte[i].titel);

      }

      state.fetchedWelcome = true;

    },

    setFetchedTitel(state, data){

      var i
      for (i = 0; i < data.length; i++){

        state.titel[i] = data[i].acf;

        // console.log(state.titel[i]);

      }

      state.fetchedTitel = true;

    },

  },
  actions: {

    initialFetch(context){

      // https://fiona.nickschnee.ch/admin/wp-json/acf/v3/
      // https://fionasbakery.ch/wordpress/wp-json/acf/v3/

      let baseurl = 'https://fiona.nickschnee.ch/admin/wp-json/acf/v3/';
      baseurl = 'https://fionasbakery.ch/wordpress/wp-json/acf/v3/';

      // console.log("hello");
      // fetch(baseurl+ 'control.php')

      fetch(baseurl+ 'news')
      .then(response => response.json())
      .then(data => context.commit('setFetchedNews', data));

      fetch(baseurl+ 'gebaeck')
      .then(response => response.json())
      .then(data => context.commit('setFetchedGebaeck', data));

      fetch(baseurl+ 'welcome')
      .then(response => response.json())
      .then(data => context.commit('setFetchedWelcome', data));

      fetch(baseurl+ 'titel')
      .then(response => response.json())
      .then(data => context.commit('setFetchedTitel', data));

    }

  },
  modules: {
  }
})
