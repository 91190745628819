<template>
  <div v-if="this.$store.state.fetchedWelcome" class=" text-left text-black lg:w-3/4 mx-auto m-0">

    <!-- <h1 class="text-white text-center">Kontakt</h1> -->

    <!-- <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6"> -->

    <div class="flex  mx-auto h-full p-4">

      <div class="h-full">

        <h3 class="text-white mb-2"> {{contact.titel}} </h3>

        <div class="contact-container text-white" v-html="contact.inhalt"> </div>

        <div class="text-white mt-8">

          <a class="text-white no-underline hover:underline" target="_blank" href="https://nickschnee.ch"> nickschnee.ch </a> |
          <a class="text-white no-underline hover:underline" target="_blank" href="https://fionasbakery.ch/wordpress/admin"> anmelden</a>

        </div>

      </div>

</div>

<!-- </div> -->

</div>
</template>

<script>
export default {
  name: 'Header',
  props: {

  },
  data() {
    return {

    }
  },
  methods: {
    compare(a, b) {
      if (a.titel < b.titel) {
        return -1;
      }
      if (a.titel > b.titel) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

  },

  computed: {

    contact() {

      var keyword = 'contact'

      var projekte = this.$store.state.welcome;

      var result = projekte.filter(obj => {
        return obj.id == keyword;
      })

      return result[0];

    },

  },

}
</script>

<style media="screen">

li{
  font-size: 2rem !important;
}

.contact-container a{
  color: white;
}

</style>
