<template>
  <div v-if="this.$store.state.fetchedWelcome" class="p-4 min-h-screen text-left text-black lg:w-3/4 mx-auto m-8">

    <h1 class="text-blue-800 text-center">{{title}}</h1>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">

      <div class="h-full border-2 rounded-xl border-gray-300 p-4 transform hover:-translate-y-2 transition ease-in-out duration-500">

        <h3 class="text-blue-800 mb-2"> {{about.titel}} </h3>

        <div class="about-container" v-html="about.inhalt">

        </div>

      </div>

      <div class="relative h-full flex items-center mb-2 lg:w-3/4 mx-auto">

        <img class="object-cover rounded-xl" :src="about.bild.url" alt="">

      </div>

  </div>

</div>
</template>

<script>
export default {
  name: 'About',
  props: {

  },
  data() {
    return {

    }
  },

  computed: {

    about() {

      var keyword = 'about'

      var projekte = this.$store.state.welcome;

      var result = projekte.filter(obj => {
        return obj.id == keyword;
      })

      // console.log(result[0]);
      return result[0];

    },

    title() {

      if(this.$store.state.fetchedTitel){

        let data = this.$store.state.titel;

        // var that = this;

        let filtered = data.filter(function (titel) {
          return titel.id == 3;
        });


        return filtered[0].titel;

      } else {

        return 'loading ...'

      }

    }

  },

}
</script>

<style media="screen">

.about-container li{

  /* font-size: 1rem !important; */
  list-style-type: none;

}

.about-container p{

  /* font-size: 1rem !important; */
  list-style-type: none;
  margin-bottom: 20px;

}

.about-container h1{

  font-weight: lighter;
  font-size: 1.5rem !important;
  margin-bottom: 20px;

}

</style>
