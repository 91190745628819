<template>
  <div class="text-left text-black lg:w-3/4 mx-auto m-8">

    <h1 class="text-center text-blue-800 pt-8">{{title}}</h1>

    <div
    @scroll="scrolling()"
    class="text-blue-800 w-10/12 mx-auto lg:w-full relative"
    :key="gebaeck.titel"
    v-for="(gebaeck, index) in allgebaeck">

    <h2 class="">{{gebaeck.titel}}</h2>

    <div @scroll="scrolling(index)" class="container flex overflow-y-auto horizontal-scroll" :id="'C' + index">

      <div class="flex flex-col bg-white p-auto">

        <div class="flex overflow-x-scroll hidescrollbar">

          <div class="flex flex-nowrap hidescrollbar">

            <div

            class="inline-block p-2"
            v-for="galerie, index in gebaeck.galerie"
            :key="index">


            <div class="relative w-80 h-80 md:w-96 md:h-96 overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">

              <img class="object-cover w-80 h-80 md:w-96 md:h-96" :src="galerie.full_image_url" :srcset="galerie.medium_srcset" alt="">

            </div>

            <div class="mt-2">

              <p class=""> {{galerie.title}} </p>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

  <!-- horizintal scrolling indicator -->

  <!-- <div class="md:hidden absolute top-0 right-0 h-full opacity" @click="scrollhorizontal(index, $event)">

  <div class="flex h-full text-center cursor-pointer">
  <div class="m-auto">
  <p class="text-4xl text-black mx-2">  > </p>
</div>
</div>

</div> -->

<!-- ENDE horizintal scrolling indicator -->


<!-- HORIZONTAL RANGE SLIDER -->

<!-- <div :id="'container' + index" v-if="checkwidth(index)" class=""> -->

<input @input="scrollhorizontal(index)" :id="'range' + index" value="0" type="range" class="mt-2 mb-8 md:hidden w-full h-2 rounded-xl bg-gray-300 appearance-none cursor-pointer">


<!-- </div> -->

<!-- ENDE HORIZONTAL RANGE SLIDER -->

</div>

</div>
</template>

<script>
export default {
  name: 'Header',
  props: {

  },
  data() {
    return {

      scrollPositions: {},

    }
  },
  methods: {
    compare(a, b) {
      if (a.titel < b.titel) {
        return -1;
      }
      if (a.titel > b.titel) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

    scrollhorizontal(index){

      let slider = document.querySelector("#range" + index);
      let value = slider.value;

      let content = document.querySelector("#C" + index);

      let max = content.scrollWidth - content.clientWidth;

      content.scrollLeft = value / 100 * max;

      // alert('Value: ' + slider.value + 'Max: ' + max + 'Current: ' +  content.scrollLeft);

    },

    scrolling(index){

      // let slider = document.querySelector("#range" + index);
      let content = document.querySelector("#C" + index);
      let slider = document.querySelector("#range" + index);

      let max = content.scrollWidth - content.clientWidth;

      slider.value = content.scrollLeft / max * 100;

      // console.log(slider.value);

    }

  },

  computed: {
    checkwidth(){

      // let content = document.querySelector("#C" + index);
      // console.log(content);
      // console.log('helloworld');

      return true;

    },

    allgebaeck() {
      // console.log(this.$store.state.projekte[2].shorturl);
      return this.$store.state.gebaeck.slice().sort(this.compare)
    },

    title() {

      if(this.$store.state.fetchedTitel){

        let data = this.$store.state.titel;

        // var that = this;

        let filtered = data.filter(function (titel) {
          return titel.id == 2;
        });


        return filtered[0].titel;

      } else {

        return 'loading ...'

      }

    }

  },

}
</script>

<style media="screen">

.opacity{

  background-color: rgba(255, 255, 255, 0.20);

}

.horizontal-scroll{

  scroll-behavior: smooth !important;

}

.hidescrollbar::-webkit-scrollbar {
  display: none;
}

.hidescrollbar{
  overflow: hidden;
  -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
  scrollbar-width: none !important;
  overflow-y: scroll !important;

}

</style>
