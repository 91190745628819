<template>
  <div class="hello">

    <div @click="scroll()" class="relative">

      <img class="object-cover w-full h-screen" :src="headerImage" alt="">

      <!-- <div class="text-center text-white absolute positioning">
      <h1>Fionas Bakery</h1>
      <h2>VERY BRITISH & MORE</h2>
    </div> -->

    <div class="absolute w-full top-0 h-full flex">

        <img class="mx-auto w-4/5 md: w-1/4 lg:w-1/4" src = "@/assets/Fionas_Card.svg" alt="My Happy SVG"/>

    </div>

    <div class="absolute place-bottom">
      <p class="text-white text-6xl rotation cursor-pointer">^</p>
    </div>

  </div>

</div>
</template>

<script>
export default {
  name: 'Header',
  props: {

  },
  data() {
    return {
      headerImage: 'https://fionasbakery.ch/gingercookies.jpg',
    }
  },
  
  methods: {

    scroll(){

      window.scroll(0, 300);


    }

  },

}
</script>

<style media="screen">

.positioning{

  font-size: 3rem;
  position:absolute;
  top:50%;
  left:50%;
  text-align:center;
  transform: translateX(-50%) translateY(-50%);


}

.place-bottom{

  font-size: 3rem;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.centering{

  margin-left: 37.5%;
  margin-top: 7%;
}

.rotation{
  transform: rotate(180deg);
}
</style>
